export const ABBRECHEN = "Abbrechen";
export const ABGESTRICHEN = "{0} abgestrichen";
export const ABRECHNUNG_ABSCHLIESSEN = "Abrechnung abschließen";
export const ABRECHNUNG_HINZUGEFUEGT = "Abrechnung hinzugefügt";
export const ABRECHNUNGEN = "Abrechnungen";
export const ADMIN_PW = "Admin-Passwort";
export const AENDERE_FLASCHENZAHL = "Ändere hier die Anzahl der Flaschen die für '{0}' verfügbar sind";
export const AENDERE_NAME_ODER_ALIAS = "Ändere den Namen oder Alias von {0}";
export const AENDERN = "Ändern";
export const AKTUALISIEREN = "Aktualisieren";
export const AKTUELL = "Aktuell";
export const AKTUELLES_RELEASE = "Aktuelles Release: {0} ({1})";
export const ALIAS_AENDERN = "Alias ändern";
export const ALIAS_GEAENDERT = "Alias geändert";
export const ALLES_KLAR = "Alles Klar";
export const ALTE_DATEN_UEBERSCHRIEBEN = "Alte Daten werden überschrieben! Backup einspielen?";
export const AM_WENIGSTEN = "Am wenigsten";
export const AN_WEN_UEBERWEISEN = "An wen möchtest du wie viel Geld überweisen?";
export const AUF_0_SETEN = "Auf 0 setzen";
export const AUF_SETZEN = "Setzen auf...";
export const BACKUP_ERFOLG = "Backup wurde erfolgreich eingespielt";
export const BACKUP_UPLOADING = "Backup wird hochgeladen und eingespielt";
export const BENUTZER_ZAHL = "Benutzer Anzahl";
export const BESCHREIBUNG = "Beschreibung";
export const BETRAG = "Betrag";
export const BETRAG_NICHT_NEGATIV = "Betrag darf nicht negativ sein";
export const BITTE_EMPFAENGER = "Bitte einen Empfänger eintragen";
export const BUDGET = "Budget";
export const BUILD_NUMBER = "Build: {0}";
export const DATENSCHUTZ = "Datenschutz";
export const DATUM = "Datum";
export const DIFFERENZ = "Differenz";
export const DOWNLOAD_BACKUP = "Download Backup";
export const EINGESPIELT = "Eingespielt";
export const EINNAHMEN = "Einnahmen";
export const EINSTELLUNGEN = "Einstellungen";
export const EINZAHLUNGEN = "Einzahlungen";
export const EMOJI_DP = "Emoji:";
export const ENTFERNEN = "Entfernen";
export const ERHOEHEN = "Erhöhen";
export const ERHOEHEN_UM = "Erhöhen um...";
export const ERINNERE_SPAETER = "Erinnere mich später";
export const ERROR_MESSAGE = "Ein fehler ist aufgetreten";
export const ES_GIBT_NEUIGKEITEN = "Es gibt Neuigkeiten für dich!";
export const FALSCHES_PASSWORT = "Falsches Passwort oder Benutzernname";
export const FAVORITEN = "Favoriten";
export const FEHLER = "Fehler";
export const FERTIG = "Fertig";
export const FLASCHENZAHL = "Flaschen-Anzahl";
export const GEBE_NEUEN_PREIS_EIN = "Gebe hier den neuen Preis für das Getränk '{0}' ein";
export const GELD = "Geld";
export const GELD_UEBERWEISEN = "Geld überweisen";
export const GELD_UEBERWIESEN = "Geld überwiesen";
export const GELD_VERTEILUNG = "Geld Verteilung";
export const GETRAENK_BEARBEITEN = "Getränk bearbeiten"
export const GETRAENK_BEARBEITEN_BESCHREIBUNG = "Ändere den Namen oder Kategorie des Getränks '{0}'";
export const GETRAENK_HINZUGEFUEGT = "Getränk hinzugefügt";
export const GETRAENK_LOESCHEN = "Getränk wird gelöscht!";
export const GETRAENKE = "Getränke";
export const GETRAENKENAME = "Getränkename";
export const GETREANKELISTE = "Getränkeliste";
export const GUTHABEN = "Guthaben";
export const GUTHABEN_AENDERN = "Guthaben ändern";
export const GUTHABEN_HINZUFUEGEN = "Guthaben hinzufügen";
export const HALLO = "Hallo";
export const HINZUFUEGEN = "Hinzufügen";
export const HISTORY = "History";
export const IMPRESSUM = "Impressum";
export const IMPRESSUM_DATENSCHUTZ = "Impressum/Datenschutz";
export const JA = "Ja";
export const KASSE_DIFFERENZ = "Kasse Differenz";
export const KASSE_GEZAEHLT = "Kasse nach Einzahlung gezählt";
export const KASSE_NACH_ABRECHNUNG = "Kasse nach Abrechnung";
export const KASSE_VOR_ABRECHNUNG = "Kasse vor Abrechnung";
export const KATEGORIE = "Kategorie";
export const KATEGORIE_GEAENDERT = "Kategorie geändert";
export const KEINE_DATEI = "Keine Datei ausgewählt";
export const KEINE_GETRAENKE = "Keine Getränke";
export const KIOSK_PW = "Kiosk-Passwort";
export const KONNTE_NICHT_EINSPIELEN = "Konnte Backup nicht einspielen";
export const KONTO = "Konto";
export const KONTOSTAND = "Kontostand";
export const LETZE_100_KAEUFE = "Letze 100 Käufe";
export const LOGIN = "Login";
export const MEHR_OPTIONEN = "Mehr optionen";
export const MEISTE_SCHULDEN = "Meiste Schulden";
export const MEMBER_LOESCHEN = "Benutzer wird gelöscht!";
export const MENGE_EINZUZAHLENDES_GELD = "Gebe hier die Menge an einzuzahlendem Geld für '{0}' ein";
export const MITGLIEDER = "Mitglieder";
export const MODIFIZIEREN = "Modifizieren";
export const NAME = "Name";
export const NAME_GEAENDERT = "Name geändert";
export const NEIN = "Nein";
export const NEU = "Neu";
export const NEUE_ABRECHNUNG = "Neue Abrechnung";
export const NEUE_VERFUEGBARKEIT = "Neue Verfügbarkeit";
export const NEUER_KASSENSTAND = "Neuer Kassenstand";
export const NEUER_PREIS = "Neuer Preis";
export const NEUES_PASSWORT_FESTLEGEN = "Neues Passwort festlegen";
export const NEUES_PASSWORT_FUER_NUTZER = "Gebe hier das neue passwort für den nutzer '{0}' ein";
export const NICHT_MEHR_ABGESTRICHEN = "{0} nicht mehr abgestrichen";
export const NO_USERS = "Keine Nutzer";
export const NUTZER_AENDERN = "Nutzer ändern";
export const NUTZER_DASHBOARD = "Nutzer Dashboard";
export const NUTZER_LEOSCHEN = "Nutzer löschen";
export const NUTZERNAME_AENDERN = "Benutzername ändern";
export const NUTZERNAME_NICHT_GEAENDERT = "Name nicht geändert";
export const NUTZERNAME_NICHT_LEER = "Nutzername darf nicht leer sein";
export const OEFFNEN = "Öffnen";
export const OFFENE_ISSUES = "Offene Issues: {0}";
export const OK = "OK";
export const OPTIONAL = "Optional";
export const PASSWORD_MIN_LAENGE = "Mindestens {0} Zeichen";
export const PASSWORT = "Passwort";
export const PASSWORT_AENDERN = "Passwörter ändern";
export const PASSWORT_GEAENDERT = "Passwort geändert";
export const PASSWORT_ZU_KURZ = "Passwort zu kurz";
export const PREIS_IN_EURO = "Preis in Euro";
export const RECHNUNGEN = "Rechnungen";
export const RECHNUNGS_NAME = "Rechnungsname";
export const RUECKGAENGIG = "Rückgängig";
export const SETZEN = "Setzen";
export const SICHER_ALTE_DATEN_UEBERSCHRIEBEN = "Das Hochladen eines Backups löscht alle aktuellen Daten und spielt den Stand des Backups ein. Alle Daten die nicht im Backup vorhanden sind, gehen verloren";
export const SICHER_TRANSAKTION_RUECKGAENIG = "Bist du dir sicher, dass du '{0}' von '{1}' vom {2} rückgängig machen möchtest?";
export const SICHER_X_LOESCHEN = "Bist du dir sicher, dass du '{0}' löschen möchtest?";
export const SICHTBARKEIT_AENDERN = "Sichtbarkeit ändern";
export const SONDERFUNKTIONEN = "Sonderfunktionen";
export const SUCHE_DOT_DOT_DOT = "Suche...";
export const TRANSAKTION_RUECKGAENIG = "Benutzer wird rückgängig gemacht!";
export const TRANSAKTIONEN = "Transaktionen";
export const UEBERWEISEN = "Überweisen";
export const UPLOAD = "Upload";
export const UPLOAD_BACKUP = "Upload Backup";
export const VALUE = "Betrag";
export const VERSTECKTE_NUTZER = "Versteckte Nutzer";
export const VERWENDUNGSZWECK = "Verwendungszweck";
export const WAEHREND_DU_WEG_WARST = "Während du weg warst sind folgende Dinge passiert:";
export const WARNUNG = "Warnung";
export const WENDE_DICH_AN_ADMIN_RUECKGAENGIG = "Bitte wende dich an den Getränkelisten verwalter um dies rückgängig zu machen";
export const WER_BIST_DU = "Wer bist du?";
export const WERT = "Wert";
export const ZEIGE_ALLE = "▼ Zeige alle ▼";
export const ZEIGE_WENIGER="▲ Zeige weniger ▲";
export const ZEITLIMIT_ABGELAUFEN = "Zeitlimit abgelaufen";
export const ZURUECK = "Zurück";